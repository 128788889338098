/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

body .mat-mdc-card {
  flex-direction: row;
  gap: 1rem;
  display: flex;
  margin: 1rem;
  padding: 0.5rem;
  // overflow: auto;
  flex-wrap: wrap;
  align-items: center;
}

.mat-mdc-tab-links .mat-tab-link-active,
.mat-mdc-tab-links .mat-tab-label-active {
  background-color: #e6e5e5 !important;
  font-weight: bold;
}

.mat-tab-link,
.mat-tab-label {
  min-width: 100px !important;
  font-size: 1.15em;
  line-height: 50px !important;
}

.unselectable {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
